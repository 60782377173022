// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { Link, useLocation } from '@remix-run/react'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import Header from '#app/components/pageLayout/Header.tsx'
import LogoCloud from '#app/components/pageLayout/LogoCloud.tsx'
import Footer from '#app/components/pageLayout/Footer.tsx'

export async function loader(params) {
	throw new Response('Not found', { status: 404 })
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	const location = useLocation()
	return (
		<>
			<div className="flex min-h-screen flex-col">
				<Header />
				<div className="container flex-1 ">
					<GeneralErrorBoundary
						statusHandlers={{
							404: () => (
								<div className=" mx-auto mb-8  mt-10  space-x-2">
									{/* <img
										className="mb-8 h-44 w-44 rounded-full object-cover"
										src="/img/landings/panda-404.jpg"
										alt="404"
									/> */}
									<p className="ml-2 text-sm font-semibold uppercase tracking-wide text-red-600">
										Error 404
									</p>
									<h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 ">
										{' '}
										Página no encontrada
										<pre className="whitespace-pre-wrap break-all text-body-lg">
											{location.pathname}
										</pre>
									</h1>
									<p className="mt-2 text-base text-gray-500">
										Es posible que ya no exista o que el enlace sea incorrecto.
									</p>
									<div className="mt-6">
										<Link
											to="/"
											className="text-gray-800-600 text-base font-bold hover:text-amber-700"
										>
											<Icon name="arrow-left">
												Vuelve a la página de inicio
											</Icon>
										</Link>
									</div>
								</div>
							),
						}}
					/>{' '}
				</div>
			</div>
			<LogoCloud />
			<Footer />
		</>
	)
}
